import React, { FC, Fragment, useEffect, useState } from "react";
import { useStaticQuery, graphql, HeadFC, Link } from "gatsby";
import { useLocation } from "@reach/router";
import Layout from "../../components/layout/Layout";
import parse from "html-react-parser";
import Seo from "../../components/Seo";
import BlogCard from "../../components/blog/BlogCard";
import "./blog.css";
import Breadcrumb from "../../components/common/Breadcrumb";
import Pagination from "../../components/common/Pagination/Pagination";

const ITEMS_PER_PAGE = 6; // Adjust this value as needed

const Blog: FC<any> = ({ data: allData }) => {
  // const seoInfo = allData.allWpPage.nodes[0].seo.fullHead;
  const blogList = allData?.allWpPost?.nodes;
  const categoryList = allData?.allWpCategory?.nodes;
  const blogHeading =
    allData?.allWpPage?.nodes[0]?.blogPageInformation?.heading;
  const blogDescription =
    allData?.allWpPage?.nodes[0]?.blogPageInformation?.description;
  const crumbsData = [{ label: "Blog", link: `/blog` }];
  //console.log("blogList", blogList);

  const totalPages = Math.ceil(blogList.length / ITEMS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const postList = blogList.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <Layout>
        <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain pt-[10px] pb-[50px]">
          <Breadcrumb crumbs={crumbsData} />
          <h1 className="xl:text-[45px] lg:text-[35px] text-[25px] mt-[-15px] font-sofiaMedium font-medium text-black ml-[-4px] md:min-h-[40px] min-h-[35px]">
            {blogHeading || "Our Latest Blogs"}
          </h1>
          <div className="blog-text mt-[5px] md:mb-[10px] mb-[5px] w-full " dangerouslySetInnerHTML={{
            __html: blogDescription
          }} />

          {categoryList?.length > 0 ? (
            <div className="categoryList md:mb-5 mb-3">
              <ul className="flex flex-wrap md:gap-3 gap-1">
                <Link to={`/blog`}
                  className={`bg-black text-white flex md:py-3 py-2 md:px-5 px-3 rounded-[21px] font-sofiaRegular font-normal text-[12px]`}
                >
                  All articles
                </Link>
                {categoryList.map((category: any, index: any) => (
                  <Link
                    to={`/blog-category/${category.slug}`}
                    key={category.id}
                    className="bg-[#F2F2F2] flex md:py-3 py-2 md:px-5 px-3 rounded-[21px] font-sofiaRegular font-normal text-[12px]"
                  >
                    {category.name}
                  </Link>
                ))}
              </ul>
            </div>
          ) : <></>}
          <div className="flex flex-wrap -m-2 md:gap-y-[25px] gap-y-[12px]">
            {postList.map((bloginfo: any, index: any) => (
              <Fragment key={index}>
                <BlogCard blog={bloginfo} key={index} />
              </Fragment>
            ))}
          </div>
          {postList?.length > 0 && totalPages > 1 ? (
            <div className="pt-4 pb-4">
              <Pagination
                page={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : <></>}
        </section>

      </Layout>
    </>
  );
};

export default Blog;
export const query = graphql`
	query {
		allWpCategory(filter: { count: { gt: 0 } }) {
			nodes {
				id
				name
				slug
			}
		}
		allWpPage(filter: { isPostsPage: { eq: true } }) {
			nodes {
				id
				status
				title
				slug
				uri
				blogPageInformation {
					description
					heading
				}
				seo {
					fullHead
				}
			}
		}
		allWpPost(filter: { status: { eq: "publish" } }) {
			nodes {
				id
				title
				status
				slug
				content
				author {
					node {
						firstName
						lastName
						name
					}
				}
				categories {
					nodes {
						name
					}
				}
				featuredImage {
					node {
						publicUrl
					}
				}
			}
		}
	}
`;
export const Head: HeadFC = ({ data }: any) => {
  const seoInfo = data.allWpPage.nodes[0].seo.fullHead;
  const parseHtml: any = parse(seoInfo);
  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === "application/ld+json"
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }

  // Retrieve current location from @reach/router's useLocation
  const location = useLocation();
  const canonicalUrl = `https://spartanpeptides.com${location.pathname}`;

  return (
    <>
      {parseHtml}
      <Seo info={seoInfo} />
      <link rel="canonical" href={canonicalUrl}></link>
    </>
  );
};
